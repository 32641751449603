<template>
    <router-view/>
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style lang="scss">
    @import "bootstrap/dist/css/bootstrap.css";
    @import "bootstrap-vue/dist/bootstrap-vue.css";
    /* @import 'assets/scss/fonts';
     */
    /* @import '../assets/scss/global'; */
</style>
