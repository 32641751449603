// require('./bootstrap');

import { createApp } from 'vue';
import App from './App.vue';
import { store } from './store'
import router from './router'
import VueSelect from "vue-select";
import VueTheMask from 'vue-the-mask';
import moment from 'moment';
import { required, email, min, min_value, confirmed } from '@vee-validate/rules';
import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import pt from './utils/translate/pt_BR.json'
import validate from './rules/cpf'


configure({
    generateMessage: localize('pt_BR', {
        "messages": pt.messages
    }),
});

defineRule('required', required)
defineRule('email', email)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('min_value_money', min_value)
defineRule('cpf', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true;
    }

    if (!validate(value)){
        return 'CPF inválido'
    }
    return validate(value);
  })


const app = createApp(App)
app.component("v-select", VueSelect)
app.config.globalProperties.$filters = {
    currencyRS(valor) {
        valor = Number(valor);
        if(!isNaN(valor)){
            return valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
            });
        } else {
            return ""
        }
    },
    maskPhone(value) {
        if (!value) {
            return value;
        }

        return value
            .replace(/\D/g, "")
            .replace(/^(\d{2})(\d)/g,"($1) $2")
            .replace(/(\d)(\d{4})$/,"$1-$2")
    },
    maskCPF(value) {
        if (!value) {
            return value;
        }

        return value
            .replace(/\b(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2})\b/, "$1.$2.$3-$4")
    },
}
app.use(VueTheMask)
app.use(moment)
app.use(store)
app.use(router)
app.mount('#app');
